body {
  color: #000 !important;
  background: #fff !important; }
  body a {
    color: #000 !important; }

#wrapper {
  padding: 0 !important; }

#header {
  position: relative !important;
  width: 100% !important;
  overflow: hidden; }

.header-top-bar,
#nav,
.footer-bottom-bar {
  display: none; }

.footer-copy-bar {
  padding: 0; }

.row {
  padding: 0;
  margin: 0; }
  .row div[class*="col"] {
    width: 100%;
    padding: 0;
    margin: 0;
    float: none; }

.copy {
  float: left;
  font-size: 12px;
  line-height: 15px; }

.footer {
  background: none;
  padding: 20px 0 0;
  color: #000; }
  .footer a {
    color: #000; }
  .footer p {
    display: none; }

.footer-logo,
.footer-nav,
.footer-section .col-xs-12.col-md-3.hidden-sm.hidden-xs {
  display: none; }

.hidden-print {
  display: none !important; }

.footer-contacts {
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
  font-size: 12px;
  line-height: 15px; }
  .footer-contacts li {
    padding: 0;
    margin: 0 0 5px; }
    .footer-contacts li a {
      color: #000; }

.product-description .drop {
  display: block !important; }

.header-logo {
  width: 200px;
  display: block;
  margin: 0 0 30px; }
  .header-logo img {
    display: block;
    width: 100%;
    height: auto; }

.title-product {
  display: block;
  font-size: 24px;
  line-height: 26px;
  margin: 0 0 10px; }

.price-box {
  display: block;
  margin: 0 0 20px;
  padding: 0; }
  .price-box .price {
    display: block;
    font-size: 24px;
    line-height: 26px; }
  .price-box .price-note {
    display: block;
    font-size: 14px;
    line-height: 16px; }

.header {
  padding: 0;
  margin: 0;
  background: none;
  position: relative; }

#wrapper {
  padding: 0; }

.slideshow .btn-prev,
.slideshow .btn-next,
.slideshow .btn-zoom,
.add-menu-list,
.page-heading-bar .results {
  display: none !important; }

.slideshow .pagination {
  padding: 0;
  margin: 0;
  list-style: none; }
  .slideshow .pagination li {
    float: left;
    margin: 0 10px 5px 0;
    border: none; }
    .slideshow .pagination li a {
      padding: 0;
      border: none; }

.total-info {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }
  .total-info li {
    float: left;
    width: 150px; }

.specifications-block {
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 20px; }
  .specifications-block dl {
    overflow: hidden;
    margin: 0; }
    .specifications-block dl dt {
      float: left;
      width: 200px; }
    .specifications-block dl dt {
      display: block;
      overflow: hidden; }

.product-description {
  display: none; }

.footer h4 {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin: 0 0 10px; }

#wrapper {
  position: relative;
  width: 100%; }

.comments-text {
  padding-right: 40px; }

.popup-holder {
  display: none; }

.home-gallery {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 0 20px; }
  .home-gallery .slideset {
    width: 100%;
    overflow: hidden;
    position: relative; }
  .home-gallery .slide {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    display: block;
    width: 100%; }
    .home-gallery .slide + .slide {
      display: none; }
    .home-gallery .slide img {
      display: block;
      width: 100% !important;
      height: auto !important;
      margin: 0 !important; }
  .home-gallery .img-box {
    height: auto; }
  .home-gallery .switcher {
    display: none; }

.tabs-info-section {
  display: none; }

.container,
.row {
  width: 100% !important;
  overflow: hidden;
  max-width: none !important; }

.base-description {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 30px 0; }
  .base-description .col-sm-3 {
    float: left !important;
    width: 25% !important;
    padding: 0 15px !important; }
    .base-description .col-sm-3 img {
      display: block;
      width: 100%;
      height: auto; }

.image-section-bg,
.gallery-latest-offers,
.decor-top,
.decor-bottom,
.image-right50-bg {
  display: none !important; }

.twoboxes .alignleft {
  float: left;
  margin: 0 10px 10px 0; }

.base-fade-gallery {
  display: none; }

.twoboxes {
  padding: 20px 0; }

.twoboxes.text-white {
  color: #000; }

.vertical-align-middle {
  height: auto !important; }

.base-blockquote {
  border: none !important;
  margin: 0 0 10px; }

h1, h2, h3, h4, h5 {
  margin: 0 0 10px; }

p {
  margin: 0 0 10px; }

.item {
  padding: 9px 0 19px;
  position: relative;
  border-bottom: 1px solid #000;
  font-weight: normal;
  color: #000; }
  .item .img-box {
    float: left;
    width: 321px;
    margin: 10px 21px 0 0;
    overflow: visible; }
    .item .img-box img {
      display: block;
      width: 100%;
      height: auto; }
    .grid-active .item .img-box {
      float: none;
      width: 100%;
      margin: 0 0 14px; }
  .item .text-holder {
    overflow: hidden; }
  .item .price-box {
    float: right;
    margin: 0; }
  .item h2 {
    margin: 0 0 10px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600; }
    .grid-active .item h2 {
      margin: 0; }
  .item .heading {
    padding: 16px 0 0;
    margin: 0 0 9px; }
    .grid-active .item .heading {
      padding: 0; }
  .item .info-list {
    padding: 0;
    list-style: none;
    margin: 0 0 5px;
    font-size: 12px;
    line-height: 16px; }
    .item .info-list > li {
      margin: 0 0 10px; }
      .item .info-list > li .icon-holder {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 0;
        font-size: 10px;
        line-height: 10px; }
        .item .info-list > li .icon-holder img {
          vertical-align: top; }
      .item .info-list > li .text {
        display: inline-block;
        vertical-align: middle; }
  .item .text-description {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 15px; }
    .item .text-description p {
      margin: 0; }
    .grid-active .item .text-description {
      display: none; }
  .item footer {
    display: block; }
    .item footer .btn {
      float: right;
      padding: 8px 11px;
      min-width: 100px;
      font-size: 14px;
      line-height: 18px;
      margin: 0; }
      .grid-active .item footer .btn {
        position: absolute;
        right: 18px;
        bottom: 45px; }
  .item .list-description {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 15px; }
    .item .list-description > li {
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 0 0;
      position: relative;
      padding: 0 0 0 9px; }
      .item .list-description > li:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        border: solid;
        border-width: 3px 4px;
        border-color: transparent transparent transparent #000;
        margin: -3px 0 0; }
    .grid-active .item .list-description {
      display: none; }
  .item .message-box {
    position: relative;
    overflow: hidden;
    padding: 12px 17px 15px;
    background: #000;
    line-height: 21px;
    margin: 13px 0 17px;
    color: #fff; }
    .item .message-box p {
      margin: 0; }
    .item .message-box .icon {
      float: left;
      width: 22px;
      margin: 0 13px 0 0; }
      .item .message-box .icon img {
        display: block;
        width: 100%;
        height: auto; }
    .item .message-box .text-holder {
      overflow: hidden;
      display: block; }
    .grid-active .item .message-box {
      display: none; }
  .item .stock-message {
    margin: 0 0 12px;
    padding: 5px 12px;
    background: #000;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden; }
    .item .stock-message .title {
      float: left;
      margin: 0 20px 0 0;
      font-weight: normal; }
    .item .stock-message .info {
      float: left; }
      .item .stock-message .info li {
        float: left;
        margin: 0 23px 0 0; }
        .item .stock-message .info li:last-child {
          margin-right: 0; }
      .item .stock-message .info img {
        width: 17px;
        vertical-align: top; }
    .grid-active .item .stock-message {
      display: none; }
  .grid-active .item {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    padding: 0 18px 20px;
    margin: 0 -3px 0 0;
    border: none;
    position: relative; }
    .grid-active .item .add-menu-list {
      display: none; }

.slideshow {
  position: relative;
  width: 420px;
  overflow: hidden; }
  .slideshow .slideshow-holder {
    margin: 0 0 10px;
    width: 100%;
    padding: 0;
    overflow: hidden;
    height: auto !important; }
  .slideshow .slideset {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: auto !important; }
  .slideshow .slide {
    position: relative;
    width: 100% !important;
    height: auto !important; }
    .slideshow .slide img {
      display: block;
      width: 100%;
      height: auto; }
    .slideshow .slide + .slide {
      display: none !important; }
  .slideshow .pagination {
    width: 120%;
    position: relative;
    overflow: hidden;
    margin: 0; }
    .slideshow .pagination li {
      float: left;
      width: 77px !important;
      margin: 0 8px 0 0; }
    .slideshow .pagination img {
      display: block;
      width: 100%;
      height: auto; }
  .slideshow .btn-prev,
  .slideshow .btn-next {
    display: none !important; }

.blue-block.loading-block {
  margin: 0 0 20px; }

.img-content {
  margin: 0 0 15px; }

.nav.nav-tabs {
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
  overflow: hidden;
  border-bottom: 1px solid #000; }
  .nav.nav-tabs li {
    float: left;
    padding: 0;
    margin: 0 30px 0 0;
    display: none; }
    .nav.nav-tabs li.active {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px; }
    .nav.nav-tabs li a {
      display: block;
      padding: 10px 0;
      border: none; }

.alignright {
  float: right;
  margin: 0 0 5px 10px; }

.content .content-block {
  padding: 0;
  margin: 0 0 20px; }

.info-tabs {
  margin: 0 0 20px; }
